import React, { FC } from 'react';

import './Spinner.css';

const Spinner: FC = () => (
  <div className="geecko-skills-spinner__wrapper">
    <div className="geecko-skills-spinner__child geecko-skills-spinner__bounce-1" />
    <div className="geecko-skills-spinner__child geecko-skills-spinner__bounce-2" />
    <div className="geecko-skills-spinner__child geecko-skills-spinner__bounce-3" />
  </div>
);

export default Spinner;
