import { Expose, plainToClass, Type } from 'class-transformer';
import { Role } from 'models/auth/Role';

/**
 * Модель пользователя.
 */
export class User {
  /**
   * Создает экземпляр пользователя из JSON объекта.
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(User, json);
  }

  @Expose({ name: 'id' })
  public readonly id!: number;

  @Expose({ name: 'first_name' })
  public readonly firstName!: string;

  @Expose({ name: 'last_name' })
  public readonly lastName!: string;

  @Expose({ name: 'email' })
  public readonly email!: string;

  @Expose({ name: 'is_you' })
  public readonly isYou!: boolean;

  @Expose({ name: 'permissions' })
  public readonly permissions!: { [persmission: string]: string };

  @Type(() => Role)
  public role!: Role;

  public get fullName(): string {
    return [this.lastName, this.firstName].filter((item) => item).join(' ');
  }

  public can(permission: string): boolean {
    return typeof this.permissions[permission] === 'boolean' ? !!this.permissions[permission] : false;
  }
}
