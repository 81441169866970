import { IntlShape } from 'react-intl';
import addSeconds from 'date-fns/add_seconds';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import format from 'date-fns/format';
import getTime from 'date-fns/get_time';
import ruLocale from 'date-fns/locale/ru';
import queryString from 'query-string';

import en from './lang/en.json';

export type Nullable<T> = T | null;

export function isLocalStorageAvailable(): boolean {
  if (typeof localStorage !== 'undefined') {
    try {
      localStorage.setItem('feature_test', 'yes');
      if (localStorage.getItem('feature_test') === 'yes') {
        localStorage.removeItem('feature_test');
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
}

export function actualTimeNew(serverDiff: number, fromTime?: string | number | Date): Date {
  if (fromTime) {
    return addSeconds(new Date(fromTime), serverDiff);
  }
  return addSeconds(new Date(), serverDiff);
}

export const getUnixTime = (time?: Date | string | number): number => {
  if (time === undefined) {
    return Math.floor(getTime(new Date()) / 1000);
  }
  return Math.floor(getTime(time) / 1000);
};

export function pluralizeTitle(value: number, titles: string[]): string {
  const cases = [2, 0, 1, 1, 1, 2];

  return titles[value % 100 > 4 && value % 100 < 20 ? 2 : cases[Math.min(value % 10, 5)]];
}

export function formatDateTime(date: string, withSeconds = true) {
  if (!withSeconds) {
    return format(new Date(date), 'DD.MM.YYYY HH:mm', ruLocale);
  }
  return format(new Date(date), 'DD.MM.YYYY HH:mm:ss', ruLocale);
}

export function pad(num: string | number, size: number): string {
  let s = num.toString();
  while (s.length < size) {
    s = `0${s}`;
  }
  return s;
}

export function differenceBetweenTwoDates(dateA: string, dateB: string) {
  return differenceInSeconds(new Date(dateA), new Date(dateB));
}

export const isTouchDevice = () =>
  !!(typeof window !== 'undefined' && 'ontouchstart' in window) ||
  !!(typeof navigator !== 'undefined' && (navigator.maxTouchPoints || navigator.msMaxTouchPoints));

export function formatDurationWithLabels(totalSeconds: number): string {
  if (isNaN(totalSeconds)) {
    return '0';
  }

  totalSeconds = Math.floor(totalSeconds);
  if (totalSeconds < 0) {
    totalSeconds = 0;
  }

  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const durationParts: string[] = [];
  if (hours > 0) {
    durationParts.push(`${hours}h`);
  }
  if (hours > 0 || minutes > 0) {
    durationParts.push(`${minutes}m`);
  }
  durationParts.push(`${seconds}s`);

  return durationParts.join(' ');
}

export function formatDurationWithLongLabels(intl: IntlShape, totalSeconds: number): string {
  if (isNaN(totalSeconds)) {
    return '0';
  }

  totalSeconds = Math.floor(totalSeconds);
  if (totalSeconds < 0) {
    totalSeconds = 0;
  }

  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const durationParts: string[] = [];
  if (hours > 0) {
    durationParts.push(
      intl.formatMessage({ id: 'skills.plural.hours', defaultMessage: en['skills.plural.hours'] }, { count: hours }),
    );
  }
  if (hours > 0 || minutes > 0) {
    durationParts.push(
      intl.formatMessage(
        { id: 'skills.plural.minutes', defaultMessage: en['skills.plural.minutes'] },
        { count: minutes },
      ),
    );
  }
  durationParts.push(
    intl.formatMessage(
      { id: 'skills.plural.seconds', defaultMessage: en['skills.plural.seconds'] },
      { count: seconds },
    ),
  );

  return durationParts.join(' ');
}

export function formatDuration(totalSeconds: number): string {
  if (isNaN(totalSeconds)) {
    return '0';
  }
  totalSeconds = Math.floor(totalSeconds);
  if (totalSeconds < 0) {
    totalSeconds = 0;
  }
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const durationParts: number[] = [];
  if (hours > 0) {
    durationParts.push(hours);
  }
  durationParts.push(minutes);
  durationParts.push(seconds);

  return durationParts.map((item, index) => (index > 0 ? pad(item, 2) : item)).join(':');
}

export function formatDurationSoft(intl: IntlShape, totalSeconds: number): string {
  if (isNaN(totalSeconds)) {
    return '-';
  }
  totalSeconds = Math.ceil(totalSeconds);
  if (totalSeconds < 0) {
    totalSeconds = 0;
  }
  if (totalSeconds < 5 * 60) {
    return formatDuration(totalSeconds);
  }

  const minutes = Math.ceil(totalSeconds / 60);

  const durationParts: string[] = [];

  durationParts.push(
    intl.formatMessage(
      { id: 'skills.public.test.timer.minutes', defaultMessage: en['skills.public.test.timer.minutes'] },
      { count: minutes },
    ),
  );

  return durationParts.map((item, index) => (index > 0 ? pad(item, 2) : item)).join(' ');
}

export function ucfirst(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function addClass(selector: string, className: string): boolean {
  const htmlElement = document.querySelector(selector);
  if (htmlElement) {
    htmlElement.classList.add(className);
    return true;
  }

  return false;
}

export function removeClass(selector: string, className: string): boolean {
  const htmlElement = document.querySelector(selector);
  if (htmlElement) {
    htmlElement.classList.remove(className);
    return true;
  }

  return false;
}

export const copyToClipboard = (valueToCopy: string) => {
  const el = document.createElement('textarea');
  el.value = valueToCopy;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const nullableString = (value: string | undefined | null): string => {
  if (typeof value === 'undefined' || value === null) {
    return '';
  }

  return value;
};

type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};

export function cloneReadable<T>(val: T): Mutable<T> {
  return val as Mutable<T>;
}

export const replaceQueryStringWithParameter = (searchPart: string, key: string, value: any) => {
  const queryParams = cloneReadable(queryString.parse(searchPart));
  queryParams[key] = value;

  return queryString.stringify(queryParams);
};

export const openTab = (url: string) => {
  const win = window.open(url, '_blank');
  if (win) {
    win.focus();
  }
};

export const validateUrl = (url: string): boolean =>
  /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi.test(url);
