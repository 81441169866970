import { createAction, createAsyncAction } from 'typesafe-actions';

import { BroadcastCredentials, Session } from '@Models/tests/result/Session';

import { Nullable } from '../../../helpers';

export const addEventsToSyncAction = createAction('examination/add-events-to-sync')<string | string[]>();

export const syncEventsAction = createAsyncAction(
  'examination/sync-events-request',
  'examination/sync-events-success',
  'examination/sync-events-failure',
)<string, string[], Error>();

interface FetchSessionParams {
  sessionId: string;
  broadcastHash?: string | null;
}

export const fetchSessionAction = createAsyncAction(
  'examination/fetch-session-request',
  'examination/fetch-session-success',
  'examination/fetch-session-failure',
)<FetchSessionParams, { session: Session; broadcastCredentials: Nullable<BroadcastCredentials> }, Error>();

export const replaceSessionAction = createAction('examination/replace-session')<Session | undefined>();
