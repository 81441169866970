import { Expose, plainToClass, Type } from 'class-transformer';
import { Nullable } from 'helpers';
import { CodeReview } from 'models/tests/code-review/CodeReview';
import { CodingSingle } from 'models/tests/task/CodingSingle';
import { DatabaseTask } from 'models/tests/task/DatabaseTask';
import { Test } from 'models/tests/tests/Test';

export class Template {
  /**
   * Создает экземпляр комментария к код ревью из JSON объекта.
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(Template, json);
  }

  public id!: number;

  public name!: string;

  @Expose({ name: 'public_name' })
  public publicName!: string | null;

  @Expose({ name: 'welcome_text' })
  public welcomeText!: Nullable<string>;

  @Type(() => TemplateItems)
  public items!: TemplateItems;

  @Expose({ name: 'version' })
  public version!: number;
}

export class TemplateItems {
  public count!: number;

  @Type(() => TemplateItem)
  public items!: TemplateItem[];
}

export class TemplateItem {
  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'type_id' })
  public type!: number;

  @Expose({ name: 'name' })
  public name!: string;

  @Expose({ name: 'key' })
  public key!: string;

  @Type(() => CodingSingle)
  @Expose({ name: 'task_coding' })
  public taskCoding?: CodingSingle;

  @Type(() => Test)
  @Expose({ name: 'task_test' })
  public taskTest?: Test;

  @Type(() => CodeReview)
  @Expose({ name: 'task_code_review' })
  public taskCodeReview?: CodeReview;

  @Type(() => DatabaseTask)
  @Expose({ name: 'task_database' })
  public taskDatabase?: DatabaseTask;
}
