import { Expose, plainToClass } from 'class-transformer';

/**
 * Модель тестовой среды
 */
export class Environment {
  /**
   * Создает экземпляр среды из JSON объекта.
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(Environment, json);
  }

  @Expose({ name: 'name' })
  public readonly name!: string;

  @Expose({ name: 'use_lsp' })
  public readonly useLsp!: boolean;

  @Expose({ name: 'lsp_version' })
  public readonly lspVersion!: number;

  @Expose({ name: 'lsp_pathname' })
  public readonly lspPathName!: string | null;

  @Expose({ name: 'lsp_root_uri' })
  public readonly lspRootUri!: string | null;

  @Expose({ name: 'lsp_file_uri' })
  public readonly lspFileUri!: string | null;
}
