import 'core-js/modules/es.promise.finally';
import 'core-js/proposals/string-replace-all';
import './fontawesome';

import React, { FC, lazy, Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { LoginPage } from 'pages/management/LoginPage/LoginPage';
import WelcomePage from 'pages/skilltest/MainPage';
import NotFoundPage from 'pages/skilltest/NotFoundPage';

import Spinner from './components/Spinner/Spinner';
import en from './lang/en.json';
import ru from './lang/ru.json';
import { forceLocaleSelector } from './state/modules/common/selectors';

import './App.css';

const messages: { [index: string]: any } = {
  ru,
  en,
};

if (window.uiColorAccent) {
  document.documentElement.style.setProperty('--brand-color', window.uiColorAccent);
}

const AsyncManagementLayout = lazy(() => import('pages/management/ManagementLayout'));
const AsyncSkillTestPage = lazy(() => import('pages/skilltest/SkillTestPage'));
const AsyncCrossBoardingPage = lazy(() => import('pages/skilltest/CrossBoarding/CrossBoardingPage'));
const AsyncPromoPage = lazy(() => import('pages/skilltest/PromoIntroPage/PromoIntroPage'));
const AsyncGraphQlPage = lazy(() => import('pages/GraphQlPage'));

const App: FC = () => {
  const locale = useSelector(forceLocaleSelector);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Suspense
        fallback={
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner />
          </div>
        }
      >
        <Switch>
          <Route exact path="/test/:sessionId/:section?" component={AsyncSkillTestPage} />
          <Route exact path="/cross-boarding" component={AsyncCrossBoardingPage} />
          <Route exact path="/" component={WelcomePage} />
          <Route exact path="/management/login" component={LoginPage} />
          <Route path="/management" component={AsyncManagementLayout} />
          <Route path="/graph_test" component={AsyncGraphQlPage} />
          <Route exact path="/:promoUrl" component={AsyncPromoPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </IntlProvider>
  );
};

export default App;
