import { Expose, plainToClass, Type } from 'class-transformer';
import { User } from 'models/auth/User';

export class SessionRate {
  /**
   * Создает экземпляр оценки сессии из JSON объекта.
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(SessionRate, json);
  }

  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'comment' })
  public comment!: string | null;

  @Expose({ name: 'recommend_status' })
  public recommendStatus!: RecommendStatus;

  @Expose({ name: 'user' })
  @Type(() => User)
  public user!: User;
}

export enum RecommendStatus {
  Recommend = 1,
  NotRecommend = 2,
}
