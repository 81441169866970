import { produce } from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import * as ExaminationAction from './actionCreators';
import { ExaminationState, initialExaminationState } from './types';

type ExaminationActionType = ActionType<typeof ExaminationAction>;

export const examinationReducer = (
  state: ExaminationState = initialExaminationState,
  action: ExaminationActionType,
): ExaminationState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case getType(ExaminationAction.addEventsToSyncAction):
        const newEvents = Array.isArray(action.payload) ? action.payload : [action.payload];
        draft.tasksEvents = [...draft.tasksEvents, ...newEvents];
        break;

      case getType(ExaminationAction.syncEventsAction.success):
        if (action.payload) {
          draft.tasksEvents = [...draft.tasksEvents].filter((event) => action.payload.indexOf(event) < 0);
        }
        break;

      case getType(ExaminationAction.fetchSessionAction.request):
        draft.currentSession.loading = true;
        draft.currentSession.loaded = false;
        draft.currentSession.error = undefined;
        break;

      case getType(ExaminationAction.fetchSessionAction.success):
        draft.currentSession.loading = false;
        draft.currentSession.loaded = true;
        draft.currentSession.item = action.payload.session;
        draft.broadcastCredentials = action.payload.broadcastCredentials;
        break;

      case getType(ExaminationAction.fetchSessionAction.failure):
        draft.currentSession.loading = false;
        draft.currentSession.error = action.payload;
        break;

      case getType(ExaminationAction.replaceSessionAction):
        draft.currentSession.item = action.payload;
        break;
    }
  });
};
