import { NetworkEntity } from '@State/types';

import { BroadcastCredentials, Session } from '@Models/tests/result/Session';

export const REDUX_EXAMINATION_MODULE_NAME = 'examination';

export interface ExaminationState {
  tasksEvents: string[];

  currentSession: NetworkEntity<Session>;

  broadcastCredentials: BroadcastCredentials | null;
}

export const initialExaminationState: ExaminationState = {
  tasksEvents: [],
  currentSession: {},
  broadcastCredentials: null,
};
