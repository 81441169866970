import { Expose } from 'class-transformer';

export class SessionFeedback {
  @Expose({ name: 'rate_platform' })
  public ratePlatform!: number;

  @Expose({ name: 'rate_tasks' })
  public rateTasks!: number;

  @Expose({ name: 'comment' })
  public comment!: string;
}
