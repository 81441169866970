import { createSelector } from 'redux-views';

import { AppState } from '../../index';

import { REDUX_AUTH_MODULE_NAME } from './types';

const authStateSelector = (state: AppState) => state[REDUX_AUTH_MODULE_NAME];

export const authTokenSelector = createSelector([authStateSelector], (authState) => authState.accessToken);
export const authUserSelector = createSelector([authStateSelector], (authState) => authState.user);
export const authCompanySelector = createSelector([authStateSelector], (authState) => authState.company);
export const authCurrentCompanyIdSelector = createSelector(
  [authStateSelector],
  (authState) => authState.currentCompanyId,
);
export const authCanSwitchCompaniesSelector = createSelector(
  [authStateSelector],
  (authState) => authState.canSwitchCompanies,
);
export const authShowProgressSelector = createSelector([authStateSelector], (authState) => authState.showProgress);
export const authIsLogged = createSelector([authTokenSelector], (token) => !!token);
export const isMaintenanceModeSelector = createSelector([authStateSelector], (authState) => !!authState.maintenanceMode);
