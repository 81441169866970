import { Expose, plainToClass, Type } from 'class-transformer';

import { Language } from '../../tech/Language';

export class CodeReview {
  /**
   * Создает экземпляр задания из JSON объекта.
   *
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(CodeReview, json);
  }

  public id!: number;

  public name!: string;

  public code!: string;

  public instructions!: string;

  @Expose({ name: 'need_rewrite' })
  public needRewrite!: boolean;

  @Expose({ name: 'multi_file' })
  public multiFile!: boolean;

  @Type(() => Language)
  public language!: Language;

  @Expose({ name: 'time_limit' })
  public timeLimit?: number;

  @Expose({ name: 'files' })
  @Type(() => CodeReviewFile)
  public files!: CodeReviewFile[];
}

export class CodeReviewFile {
  /**
   * Создает экземпляр файла из JSON объекта.
   *
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(CodeReviewFile, json);
  }

  public id!: number;

  public path!: string;

  public code!: string;

  @Type(() => Language)
  public language!: Language;
}
