import { createAction } from 'typesafe-actions';

import { OutputItem } from '@Components/ConsoleOutput/ConsoleOutput';

/**
 * Очищает консольный вывод
 */
export const clearCodingOutputAction = createAction('coding/clear-coding-output')();

/**
 * Добавляет строку консольного вывода
 */
export const addCodingOutputAction = createAction('coding/add-coding-output')<OutputItem[] | OutputItem>();

/**
 * Устанавливает строку консольного вывода
 */
export const setCodingOutputAction = createAction('coding/set-coding-output')<OutputItem[] | OutputItem>();
