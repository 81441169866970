import { Expose, plainToClass, Type } from 'class-transformer';

import { TestChoiceType, TestMultipleChoiceAssessmentType } from './Test';

export enum TestQuestionType {
  Variant = 1,
  FreeForm,
}

export class TestQuestion {
  get testType(): string {
    switch (this.type) {
      case TestQuestionType.Variant:
        return 'С вариантами';
      case TestQuestionType.FreeForm:
        return 'Свободный ответ';
    }

    return '-';
  }

  /**
   * Создает экземпляр тестирования из JSON объекта.
   *
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(TestQuestion, json);
  }

  public id!: number;

  @Expose({ name: 'question_text' })
  public question!: string;

  @Expose({ name: 'question_body' })
  public body!: string;

  @Expose({ name: 'question_type' })
  public type!: TestQuestionType;

  public order!: number;

  @Expose({ name: 'time_limit' })
  public timeLimit?: number;

  @Type(() => TestQuestionVariant)
  public variants!: TestQuestionVariant[];

  @Expose({ name: 'tags' })
  public tags!: string[];

  @Expose({ name: 'choice_type' })
  public choiceType!: TestChoiceType;

  @Expose({ name: 'multiple_choice_assessment_type' })
  public multipleChoiceType?: TestMultipleChoiceAssessmentType;
}

export class TestQuestionVariant {
  public id!: number;

  public text!: string;

  @Expose({ name: 'is_correct' })
  public isCorrect!: boolean;
}
