import { safeLocalstorageGetItem } from '../../../utils/safeStorage';

export const REDUX_EDITOR_MODULE_NAME = 'editor';

export const REDUX_EDITOR_FONT_SIZE_PERSIST_KEY = 'monaco_fs';
export const REDUX_EDITOR_COLOR_SCHEME_PERSIST_KEY = 'monaco_theme';
export const REDUX_EDITOR_WORD_WRAP_PERSIST_KEY = 'monaco_word_wrap';

export interface EditorState {
  fontSize: number;
  colorScheme: string;
  wordWrap: boolean;
}

const loadEditorFontSize = () => {
  const fontSize = safeLocalstorageGetItem(REDUX_EDITOR_FONT_SIZE_PERSIST_KEY);
  return fontSize && +fontSize > 0 ? +fontSize : 14;
};

const loadEditorColorScheme = () => safeLocalstorageGetItem(REDUX_EDITOR_COLOR_SCHEME_PERSIST_KEY) || 'vs-dark';

export const initialEditorState: EditorState = {
  fontSize: loadEditorFontSize(),
  colorScheme: loadEditorColorScheme(),
  wordWrap: safeLocalstorageGetItem(REDUX_EDITOR_WORD_WRAP_PERSIST_KEY) === '1',
};
