import { getNetworkEntitySelectorState, NetworkEntitySelector } from '@State/types';

import { BroadcastCredentials, Session } from '@Models/tests/result/Session';

import { Nullable } from '../../../helpers';
import { AppState } from '../../index';

import { REDUX_EXAMINATION_MODULE_NAME } from './types';

const examinationStateSelector = (state: AppState) => state[REDUX_EXAMINATION_MODULE_NAME];

export const examinationEventsToSyncSelector = (state: AppState) => examinationStateSelector(state).tasksEvents || [];

export const examinationSessionSelector: NetworkEntitySelector<Session> = (state: AppState) => {
  return getNetworkEntitySelectorState(examinationStateSelector(state).currentSession);
};

export const examinationBroadcastCredentialsSelector = (state: AppState): Nullable<BroadcastCredentials> => {
  return examinationStateSelector(state).broadcastCredentials;
};
