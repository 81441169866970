import { NetworkEntity } from '@State/types';
import { replace } from 'connected-react-router';
import last from 'lodash/last';
import trim from 'lodash/trim';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { Session } from '@Models/tests/result/Session';

import { routerPathnameSelector } from '../router/selectors';

import { fetchSessionAction, replaceSessionAction, syncEventsAction } from './actionCreators';
import { fetchSession, syncEventsRequest } from './api';
import { examinationEventsToSyncSelector, examinationSessionSelector } from './selectors';

/**
 * Получает информацию о сессии
 */
function* fetchSessionSaga(action: ReturnType<typeof fetchSessionAction.request>): SagaIterator {
  const { sessionId, broadcastHash } = action.payload;
  try {
    const [session, broadcastCredentials] = yield call(fetchSession, sessionId, broadcastHash);
    yield put(fetchSessionAction.success({ session, broadcastCredentials }));
  } catch (e) {
    yield put(fetchSessionAction.failure(e));
  }
}

/**
 * Синхронизирует события прохождения сессии
 */
function* syncEvents(action: ReturnType<typeof syncEventsAction.request>) {
  const eventsToSync: string[] = yield select(examinationEventsToSyncSelector);
  if (eventsToSync.length > 0) {
    try {
      yield call(syncEventsRequest, action.payload, eventsToSync);
      yield put(syncEventsAction.success(eventsToSync));
    } catch (e) {
      yield put(syncEventsAction.failure(e));
    }
  } else {
    yield put(syncEventsAction.success([]));
  }
}

function* updateSessionNavigation() {
  const sessionState: NetworkEntity<Session> = yield select(examinationSessionSelector);
  const session = sessionState.item;

  // Если сессия не в статусе "Завершена", ничего не делаем
  if (!session) {
    return;
  }

  if (session.tasks.length === 0) {
    return;
  }

  const availableSections: string[] = [];

  session.tasks.forEach((_, taskIndex) => {
    availableSections.push(taskIndex.toString());
  });

  // Если текущий пользователь - залогиненный, то разрешаем показать еще отчет
  availableSections.push('report');

  let pathname: string = yield select(routerPathnameSelector);
  pathname = trim(pathname, '/');

  const urlSegments = pathname.split('/');

  // Если у нас отсутствует секция навигации, то в если они вообще есть - меняем URL
  if (availableSections.length > 0) {
    const constlastSegment = last(urlSegments);
    if (urlSegments.length < 3 || (constlastSegment && availableSections.indexOf(constlastSegment) < 0)) {
      const currentSection = availableSections[0];
      yield put(replace(`/test/${session.uid}/${currentSection}${window.location.search}`));
    }
  }
}

export function* examinationSagas() {
  yield takeLatest(syncEventsAction.request, syncEvents);
  yield takeLatest(fetchSessionAction.request, fetchSessionSaga);
  yield takeEvery(fetchSessionAction.success, updateSessionNavigation);
  yield takeEvery(replaceSessionAction, updateSessionNavigation);
}
