import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import en from '../../lang/en.json';

interface Props {
  id: string;
  values?: any;
}

export const FormattedMessageWithDefault: FC<Props> = ({ id, values }) => (
  <FormattedMessage defaultMessage={en[id as keyof typeof en]} id={id} values={values} />
);
