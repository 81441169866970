import axios, { AxiosError } from 'axios';

import { store } from '../state';
import { setAccessTokenAction, setMaintenanceMode, setUserAction } from '../state/modules/auth/actionCreators';
import { authCurrentCompanyIdSelector, authTokenSelector } from '../state/modules/auth/selectors';
import { setServerTimeDifferenceAction } from '../state/modules/common/actionCreators';
import { localeSelector } from '../state/modules/common/selectors';

export interface ApiErrorResponse {
  status?: number;
  message: string;
  validation?: { [field: string]: string[] };
  data: any;
}

export default class Api {
  public static async get(url: string) {
    try {
      const response = await Api.getInstance().get(url);
      Api.processResponse(response.data);
      return Promise.resolve(response.data);
    } catch (error) {
      const result: ApiErrorResponse = await Api.processError(error);
      return result;
    }
  }

  public static async post(url: string, data?: any) {
    try {
      const response = await Api.getInstance().post(url, data);
      Api.processResponse(response.data);
      return Promise.resolve(response.data);
    } catch (error) {
      const result: ApiErrorResponse = await Api.processError(error);
      return result;
    }
  }

  public static async put(url: string, data?: any) {
    try {
      const response = await Api.getInstance().put(url, data);
      Api.processResponse(response.data);
      return Promise.resolve(response.data);
    } catch (error) {
      const result: ApiErrorResponse = await Api.processError(error);
      return result;
    }
  }

  public static async delete(url: string) {
    try {
      const response = await Api.getInstance().delete(url);
      Api.processResponse(response.data);
      return Promise.resolve(response.data);
    } catch (error) {
      const result: ApiErrorResponse = await Api.processError(error);
      return result;
    }
  }

  private static baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

  private static getInstance() {
    const currentCompanyId = authCurrentCompanyIdSelector(store.getState()) || '';
    const authToken = authTokenSelector(store.getState());
    return axios.create({
      baseURL: Api.baseUrl,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${authToken}`,
        'X-Company-Id': currentCompanyId,
        'X-Lang': localeSelector(store.getState()),
      },
    });
  }

  private static processError(error: AxiosError): Promise<ApiErrorResponse> {
    if (error.response) {
      // Если пришел ответ 401, сбрасываем стейт авторизации
      if (error.response.status === 401) {
        store.dispatch(setAccessTokenAction(undefined));
        store.dispatch(setUserAction(undefined));

        return Promise.reject({ status: 401, message: 'Вы не авторизованы', data: error.response.data });
      }

      // Если пришел ответ 422, ошибки валидации
      if (error.response.status === 422) {
        return Promise.reject({
          status: 422,
          message: error.response.data.message,
          validation: error.response.data.errors,
          data: error.response.data,
        });
      }

      // Если пришла 400, показываем сообщение об ошибке
      if (error.response.status === 400) {
        return Promise.reject({
          status: 400,
          message: error.response.data.message,
          data: error.response.data,
        });
      }

      // Если пришел ответ 503, отмечаем это в стейте
      if (error.response.status === 503) {
        store.dispatch(setMaintenanceMode(true));

        return Promise.reject({ status: 503, message: 'Service Unavailable', data: error.response.data });
      }
    }

    return Promise.reject({ message: error.response?.data.message || 'Произошла неизвестная ошибка' });
  }

  private static processResponse(response: any) {
    if (response.server_time) {
      // FIXME: Поменять на вызов useDispatch после смены на FC
      store.dispatch(setServerTimeDifferenceAction(response.server_time));
    }
  }
}
