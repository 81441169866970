import { Expose, plainToClass, Type } from 'class-transformer';
import { Language } from 'models/tech/Language';

export class CodingSingle {
  /**
   * Создает экземпляр задания из JSON объекта.
   *
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(CodingSingle, json);
  }

  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'name' })
  public name!: string;

  @Type(() => Language)
  public language!: Language;

  @Expose({ name: 'time_limit' })
  public duration?: number;

  @Expose({ name: 'code' })
  public code!: string;

  @Expose({ name: 'instructions' })
  public instructions!: string;

  @Expose({ name: 'has_tests' })
  public hasTests!: boolean;

  @Expose({ name: 'is_tests_hidden' })
  public isTestsHidden!: boolean;

  @Expose({ name: 'difficulty' })
  public difficulty!: number | null;

  @Expose({ name: 'is_demo' })
  public isDemo!: boolean;

  @Expose({ name: 'structure' })
  public structure!: any;

  /**
   * Код автотестов
   *
   * @deprecated
   */
  public tests!: string;
}
