import Api from 'services/api';

import { BroadcastCredentials, Session } from '@Models/tests/result/Session';

import { Nullable } from '../../../helpers';

export const syncEventsRequest = (sessionId: string, events: string[]) => {
  return Api.post(`/sessions/${sessionId}/sync-events`, {
    events,
  });
};

export const fetchSession = async (
  sessionId: string,
  broadcastHash?: string | null,
): Promise<[Session, BroadcastCredentials | null]> => {
  const response = await Api.get(
    `/sessions/${sessionId}?allow_start=1${broadcastHash ? `&broadcast_hash=${broadcastHash}` : ''}`,
  );
  let broadcastCredentials: Nullable<BroadcastCredentials> = null;
  if (response.session) {
    const session = Session.fromJson(response.session);
    if (response.broadcast_credentials) {
      broadcastCredentials = {
        userId: response.broadcast_credentials.user_id,
        token: response.broadcast_credentials.token,
        channel: response.broadcast_credentials.channel,
        channelHash: response.broadcast_credentials.channel_hash,
      };
    }
    return [session, broadcastCredentials];
  }

  throw Error('Ошибка получения сессии');
};
