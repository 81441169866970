import { Expose, plainToClass, Type } from 'class-transformer';
import AES from 'crypto-js/aes';
import Hex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import addMinutes from 'date-fns/add_minutes';
import format from 'date-fns/format';
import { Nullable } from 'helpers';
import { Candidate } from 'models/candidate/Candidate';
import { DatabaseEnvironment } from 'models/tech/DatabaseEnvironment';
import { Environment } from 'models/tech/Environment';
import { Language } from 'models/tech/Language';
import { TaskRate } from 'models/tests/report/TaskRate';
import { SessionFeedback } from 'models/tests/result/SessionFeedback';
import { SessionRate } from 'models/tests/result/SessionRate';
import { UniversalTaskStructure } from 'models/tests/result/UniversalTask';
import { SessionTaskStatus, TaskType, TextFormatType } from 'models/tests/task/TaskType';
import { TestCase } from 'models/tests/task/TestCase';
import { Template } from 'models/tests/template/Template';
import { nanoid } from 'nanoid';

import { Promo } from '@Models/promo/Promo';
import { DatabaseDriver } from '@Models/tech/DatabaseDriver';

import { TestChoiceType, TestMultipleChoiceAssessmentType } from '../tests/Test';

export enum SessionStatus {
  NotStarted = 0,
  InProgress,
  Finished,
  OnPause,
  Cancelled,
}

export interface BroadcastCredentials {
  userId: string;
  token: string;
  channel: string;
  channelHash: string;
}

export class Session {
  /**
   * Создает экземпляр сессии из JSON объекта.
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(Session, json);
  }

  public uid!: string;

  public url!: string;

  @Expose({ name: 'broadcast_url' })
  public broadcastUrl!: Nullable<string>;

  @Expose({ name: 'spectate_url' })
  public spectateUrl!: Nullable<string>;

  @Expose({ name: 'name' })
  public name!: Nullable<string>;

  @Expose({ name: 'welcome_text' })
  public welcomeText!: Nullable<string>;

  @Expose({ name: 'company_name' })
  public companyName!: Nullable<string>;

  @Expose({ name: 'company_logo_dark' })
  public logoDarkTheme!: Nullable<string>;

  @Type(() => Candidate)
  public candidate!: Candidate;

  @Expose({ name: 'status_id' })
  public statusId!: SessionStatus;

  @Expose({ name: 'tasks_count' })
  public tasksCount!: number;

  @Expose({ name: 'score' })
  public score!: Nullable<number>;

  @Expose({ name: 'score_max' })
  public scoreMax!: Nullable<number>;

  @Expose({ name: 'created_at' })
  public createdAt!: string;

  @Expose({ name: 'started_at' })
  public startedAt!: string | null;

  @Expose({ name: 'finished_at' })
  public finishedAt!: string | null;

  @Expose({ name: 'redirect_uri' })
  public redirectUri!: string | null;

  @Type(() => SessionUISettings)
  @Expose({ name: 'ui' })
  public ui!: SessionUISettings;

  @Type(() => Template)
  @Expose({ name: 'template' })
  public template!: Template | null;

  @Type(() => Promo)
  @Expose({ name: 'promo' })
  public promo!: Promo | null;

  @Expose({ name: 'tasks_brief' })
  @Type(() => SessionTaskBrief)
  public tasksBrief?: SessionTaskBrief[];

  @Type(() => SessionItem)
  public tasks!: SessionItem[];

  @Type(() => SessionFeedback)
  public feedback?: SessionFeedback;

  @Expose({ name: 'rate' })
  @Type(() => SessionRate)
  public rate!: SessionRate | null;

  @Expose({ name: 'token' })
  public token!: Nullable<string>;

  @Expose({ name: 'auto_start' })
  public autoStart!: boolean;

  @Expose({ name: 'prompt_feedback' })
  public promptFeedback!: boolean;

  @Expose({ name: 'pass_by_tests' })
  public passByTests!: boolean;
}

export class SessionUISettings {
  @Expose({ name: 'primary_color' })
  public primaryColor!: string | null;

  @Expose({ name: 'button_text_color' })
  public buttonTextColor!: string | null;
}

export class SessionTaskBrief {
  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'type_id' })
  public typeId!: number;

  @Expose({ name: 'name' })
  public name!: Nullable<string>;

  @Expose({ name: 'is_demo' })
  public isDemo!: Nullable<boolean>;

  @Expose({ name: 'tech_name' })
  public techName!: Nullable<string>;

  @Expose({ name: 'time_limit' })
  public timeLimit!: Nullable<string>;

  @Expose({ name: 'is_passed' })
  public isPassed!: Nullable<boolean>;

  @Expose({ name: 'is_in_progress' })
  public isInProgress!: Nullable<boolean>;
}

export class SessionItem {
  public id!: number;

  @Expose({ name: 'type_id' })
  public typeId!: TaskType;

  @Expose({ name: 'time_limit' })
  public timeLimit?: number;

  @Expose({ name: 'status_id' })
  public status!: SessionTaskStatus;

  @Expose({ name: 'started_at' })
  public startedAt!: string;

  @Expose({ name: 'finished_at' })
  public finishedAt!: string;

  @Type(() => CodingTaskResult)
  public task?: CodingTaskResult;

  @Type(() => SessionItemScore)
  @Expose({ name: 'score' })
  public score?: SessionItemScore;

  @Type(() => TestResult)
  public test?: TestResult;

  @Type(() => CodeReviewResult)
  @Expose({ name: 'code_review' })
  public codeReview?: CodeReviewResult;

  @Type(() => DatabaseTaskResult)
  @Expose({ name: 'database' })
  public databaseTask?: DatabaseTaskResult;

  @Expose({ name: 'can_rate' })
  public canRate!: boolean;

  @Type(() => TaskRate)
  @Expose({ name: 'rate' })
  public rate?: TaskRate;

  get taskTypeName(): string {
    switch (this.typeId) {
      case TaskType.CodingSingle:
        return 'Программирование';
      case TaskType.Test:
        return 'Тестирование';
      case TaskType.CodeReview:
        return 'Code Review';
      case TaskType.Database:
        return 'Базы данных';
    }

    return '';
  }

  get finishedAtTime(): string {
    if (!this.finishedAt && this.status === SessionTaskStatus.Finished && this.timeLimit && this.startedAt) {
      // Задание закончилось по таймауту
      return format(addMinutes(new Date(this.startedAt), this.timeLimit));
    }

    return this.finishedAt;
  }

  get isTimedOut(): boolean {
    return this.status === SessionTaskStatus.Finished && !this.finishedAt;
  }
}

export class SessionItemScore {
  @Expose({ name: 'max' })
  public max!: number;

  @Expose({ name: 'score' })
  public score!: number;
}

export class CodingTaskResult {
  @Expose({ name: 'name' })
  public name!: string;

  @Type(() => Language)
  @Expose({ name: 'programming_language' })
  public language!: Language;

  @Type(() => Environment)
  @Expose({ name: 'environment' })
  public environment!: Environment;

  @Expose({ name: 'code_initial' })
  public codeInitial!: string;

  @Expose({ name: 'code_current' })
  public codeCurrent!: string;

  @Expose({ name: 'task_format' })
  public taskFormat!: TextFormatType;

  @Expose({ name: 'task_instructions' })
  public taskInstructions!: string;

  @Expose({ name: 'has_tests' })
  public hasTests!: boolean;

  @Expose({ name: 'is_tests_revealed' })
  public isTestsRevealed!: boolean;

  @Expose({ name: 'is_tests_hidden' })
  public isTestsHidden!: boolean;

  @Expose({ name: 'is_demo' })
  public isDemo!: boolean;

  @Expose({ name: 'checks_points' })
  public checksPoints!: SessionScore;

  @Type(() => PerformanceTestResult)
  @Expose({ name: 'performance_test_results' })
  public performanceTests!: PerformanceTestResult[];

  @Expose({ name: 'performance_checks' })
  public performancePoints!: SessionScore;

  @Expose({ name: 'checks' })
  public checks!: SessionScore;

  public tests!: string;

  @Expose({ name: 'time_limit' })
  public timeLimit!: number;

  @Type(() => TestCase)
  @Expose({ name: 'test_cases' })
  public testCases!: TestCase[];

  @Type(() => UniversalTaskStructure)
  @Expose({ name: 'task_structure' })
  public structure!: UniversalTaskStructure;
}

export class TestResult {
  @Expose({ name: 'name' })
  public name!: string;

  @Expose({ name: 'questions_count' })
  public questionsCount!: number;

  @Expose({ name: 'time_limit' })
  public timeLimit!: number;

  @Expose({ name: 'current_question_number' })
  public currentQuestionNumber?: number;

  @Expose({ name: 'current_question' })
  @Type(() => TestResultQuestion)
  public currentQuestion?: TestResultQuestion;

  @Expose({ name: 'questions' })
  @Type(() => TestResultQuestion)
  public questions?: TestResultQuestion[];

  @Expose({ name: 'questions_report' })
  @Type(() => TestResultQuestionReport)
  public questionsReport?: TestResultQuestionReport[];

  @Expose({ name: 'checks_points' })
  public checksPoints!: SessionScore;

  @Expose({ name: 'checks' })
  public checks!: SessionScore;
}

export class PerformanceTestResult {
  public id!: number;

  @Expose({ name: 'output' })
  public output?: string;

  @Expose({ name: 'exit_code' })
  public exitCode!: number;

  @Expose({ name: 'time_limit' })
  public timeLimit!: number;

  @Expose({ name: 'time_real' })
  public time!: number;

  @Expose({ name: 'is_passed' })
  public isPassed!: boolean;
}

export class TestResultQuestionReport {
  public id!: number;

  @Expose({ name: 'type_id' })
  public typeId!: number;

  public question!: string;

  public response?: string;

  @Expose({ name: 'time_limit' })
  public timeLimit!: number;

  @Expose({ name: 'body' })
  public body!: Nullable<string>;

  @Expose({ name: 'variants' })
  @Type(() => TestResultQuestionVariant)
  public variants?: TestResultQuestionVariant[];

  @Expose({ name: 'is_time_over' })
  public isTimeOver!: boolean;

  @Expose({ name: 'is_passed' })
  public isPassed!: boolean;

  @Expose({ name: 'response_text' })
  public responseText!: Nullable<string>;

  @Expose({ name: 'is_correct' })
  public isCorrect!: Nullable<boolean>;

  @Expose({ name: 'started_at' })
  public startedAt!: Nullable<string>;

  @Expose({ name: 'finished_at' })
  public finishedAt!: Nullable<string>;
}

export class TestResultQuestionVariant {
  public id!: number;

  public text!: string;

  @Expose({ name: 'is_correct' })
  public isCorrect!: boolean;

  @Expose({ name: 'is_selected' })
  public isSelected!: boolean;
}

export class TestResultQuestion {
  public id!: number;

  @Expose({ name: 'type_id' })
  public typeId!: number;

  public question!: string;

  public body!: string;

  @Expose({ name: 'response_text' })
  public responseText!: string;

  @Expose({ name: 'is_responded' })
  public isResponded!: boolean;

  @Expose({ name: 'time_limit' })
  public timeLimit!: number;

  @Expose({ name: 'started_at' })
  public startedAt!: number;

  @Expose({ name: 'choice_type' })
  public choiceType!: TestChoiceType;

  @Expose({ name: 'multiple_choice_assessment_type' })
  public multipleChoiceType?: TestMultipleChoiceAssessmentType;

  @Type(() => TestQuestionVariant)
  public variants?: TestQuestionVariant[];
}

export class TestQuestionVariant {
  public id!: number;

  public response!: string;

  @Expose({ name: 'is_correct' })
  public isCorrect?: boolean;

  @Expose({ name: 'is_checked' })
  public isChecked?: boolean;
}

export type TaskEventType = 'snapshot' | 'focus' | 'blur' | 'change';

export interface TaskEvent {
  id: string;
  task_id: number;
  type: TaskEventType;
  timestamp: number;
  payload?: object;
}

export class CodeReviewResult {
  public id!: number;

  @Type(() => Language)
  @Expose({ name: 'programming_language' })
  public language!: Language;

  @Expose({ name })
  public name!: string;

  @Expose({ name: 'code_initial' })
  public codeInitial!: string;

  @Expose({ name: 'code_current' })
  public codeCurrent!: string;

  @Expose({ name: 'instructions' })
  public instructions!: string;

  @Expose({ name: 'need_rewrite' })
  public needRewrite!: boolean;

  @Expose({ name: 'multi_file' })
  public multiFile!: boolean;

  @Expose({ name: 'time_limit' })
  public timeLimit!: number;

  @Expose({ name: 'comments' })
  @Type(() => CodeReviewComment)
  public comments!: CodeReviewComment[];

  @Expose({ name: 'files' })
  @Type(() => CodeReviewFile)
  public files!: CodeReviewFile[];
}

export class CodeReviewComment {
  /**
   * Создает экземпляр комментария к код ревью из JSON объекта.
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(CodeReviewComment, json);
  }

  public id!: number;

  @Expose({ name: 'line_number' })
  public lineNumber!: number;

  @Expose({ name: 'column_number' })
  public columnNumber!: number;

  @Expose({ name: 'file_id' })
  public fileId?: number;

  @Expose({ name: 'candidate_comment' })
  public candidateComment!: string;

  @Expose({ name: 'created_at' })
  public createdAt!: string;
}

export class DatabaseTaskResult {
  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'name' })
  public name!: string;

  @Expose({ name: 'database_driver' })
  @Type(() => DatabaseDriver)
  public driver!: DatabaseDriver;

  @Expose({ name: 'database_environment' })
  @Type(() => DatabaseEnvironment)
  public environment!: DatabaseEnvironment;

  @Expose({ name: 'instructions' })
  public instructions!: string;

  @Expose({ name: 'time_limit' })
  public timeLimit!: number;

  @Expose({ name: 'schema_initial' })
  public schemaInitial!: string;

  @Expose({ name: 'schema_current' })
  public schemaCurrent!: string;

  @Expose({ name: 'query_initial' })
  public queryInitial!: string;

  @Expose({ name: 'query_current' })
  public queryCurrent!: string;

  @Expose({ name: 'is_schema_readonly' })
  public isSchemaReadonly!: boolean;

  @Expose({ name: 'has_tests' })
  public hasTests!: boolean;

  @Expose({ name: 'is_tests_success' })
  public isTestSuccess!: boolean;
}

interface SessionScore {
  max: number;
  score: number;
}

const calculateSignature = (payload: string): string => {
  const key = Hex.parse('a727abb2b12b6b51b73b2b1a8c5e2d21');
  const iv = Hex.parse('a727abb2b12b6b51b73b2b1a8c5e2d21');
  return AES.encrypt(payload, key, { iv, padding: padZeroPadding }).toString();
};

export const createTaskEvent = (type: TaskEventType, taskId: number, time: number, payload?: object): string => {
  const value: TaskEvent = {
    id: nanoid(),
    task_id: taskId,
    type,
    timestamp: time,
    payload,
  };
  return calculateSignature(JSON.stringify(value));
};

export const getBasicSessionProps = (session: Session): unknown => ({
  uid: session.uid,
  status_id: session.statusId,
  autostart: session.autoStart,
});

export class CodeReviewFile {
  /**
   * Создает экземпляр файла из JSON объекта.
   *
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(CodeReviewFile, json);
  }

  public id!: number;

  public path!: string;

  @Expose({ name: 'code_initial' })
  public codeInitial!: string;

  @Expose({ name: 'code_current' })
  public codeCurrent!: string;

  @Type(() => Language)
  public language!: Language;
}
