import { Expose, Type } from 'class-transformer';
import { User } from 'models/auth/User';

export class TaskRate {
  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'score' })
  public score!: number;

  @Expose({ name: 'comment' })
  public comment!: string;

  @Type(() => User)
  @Expose({ name: 'user' })
  public user?: User;
}
