import { Expose, plainToClass, Type } from 'class-transformer';
import { DatabaseDriver } from 'models/tech/DatabaseDriver';

export class DatabaseEnvironment {
  /**
   * Создает экземпляр среды из JSON объекта.
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(DatabaseEnvironment, json);
  }

  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'name' })
  public name!: string;

  @Expose({ name: 'driver' })
  @Type(() => DatabaseDriver)
  public driver!: DatabaseDriver;
}
