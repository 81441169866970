export enum TaskType {
  CodingSingle = 1,
  Test,
  CodeReview,
  Database,
}

export enum SessionTaskStatus {
  NotStarted = 0,
  InProgress,
  Finished,
}

export type TextFormatType = 'plain' | 'markdown';
