import React from 'react';

const NotFoundPage: React.FunctionComponent<{}> = () => (
  <div className="skilltest__intro-page">
    <h1>404</h1>
    <p className="skills-welcome__paragraph">
      Страница не найдена. Если вы в поиске работы в IT, посетите&nbsp;
      <a
        href="https://geecko.ru?from=skills"
      >
          geecko.ru
      </a>
    </p>
  </div>
);

export default NotFoundPage;
