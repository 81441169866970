import { Expose, plainToClass } from 'class-transformer';

export class Candidate {
  /**
   * Создает экземпляр сессии из JSON объекта.
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(Candidate, json);
  }

  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'external_id' })
  public externalId!: string;

  @Expose({ name: 'first_name' })
  public firstName!: string;

  @Expose({ name: 'last_name' })
  public lastName!: string;

  @Expose({ name: 'family_name' })
  public familyName!: string;

  @Expose({ name: 'email' })
  public email!: string;

  @Expose({ name: 'skype' })
  public skype!: string;

  @Expose({ name: 'hh' })
  public headHunter!: string;

  @Expose({ name: 'moikrug' })
  public moiKrug!: string;

  @Expose({ name: 'linkedin' })
  public linkedIn!: string;

  @Expose({ name: 'github' })
  public gitHub!: string;

  @Expose({ name: 'gitlab' })
  public gitLab!: string;

  @Expose({ name: 'bitbucket' })
  public bitBucket!: string;

  @Expose({ name: 'stackoverflow' })
  public stackOverflow!: string;

  @Expose({ name: 'toster' })
  public toster!: string;

  public get fullName(): string {
    return [this.lastName, this.firstName, this.familyName].filter((item) => item).join(' ');
  }
}
