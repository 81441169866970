import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { authReducer } from './modules/auth/reducer';
import { AuthState, initialAuthState, REDUX_AUTH_MODULE_NAME } from './modules/auth/types';
import { codingReducer, CodingState, initialCodingState, REDUX_CODING_MODULE_NAME } from './modules/coding/types';
import { commonReducer } from './modules/common/reducer';
import { CommonState, initialCommonState, REDUX_COMMON_MODULE_NAME } from './modules/common/types';
import { editorReducer } from './modules/editor/reducer';
import { EditorState, initialEditorState, REDUX_EDITOR_MODULE_NAME } from './modules/editor/types';
import { examinationReducer } from './modules/examination/reducer';
import { ExaminationState, initialExaminationState, REDUX_EXAMINATION_MODULE_NAME } from './modules/examination/types';
import { rootSaga } from './sagas';

export interface AppState {
  [REDUX_COMMON_MODULE_NAME]: CommonState;
  [REDUX_AUTH_MODULE_NAME]: AuthState;
  [REDUX_EDITOR_MODULE_NAME]: EditorState;
  [REDUX_EXAMINATION_MODULE_NAME]: ExaminationState;
  [REDUX_CODING_MODULE_NAME]: CodingState;
  router?: RouterState;
}

export const defaultState: AppState = {
  [REDUX_COMMON_MODULE_NAME]: initialCommonState,
  [REDUX_AUTH_MODULE_NAME]: initialAuthState,
  [REDUX_EDITOR_MODULE_NAME]: initialEditorState,
  [REDUX_EXAMINATION_MODULE_NAME]: initialExaminationState,
  [REDUX_CODING_MODULE_NAME]: initialCodingState,
};

const createRootReducer = (history: any) => {
  return combineReducers({
    [REDUX_COMMON_MODULE_NAME]: commonReducer,
    [REDUX_AUTH_MODULE_NAME]: authReducer,
    [REDUX_EDITOR_MODULE_NAME]: editorReducer,
    [REDUX_EXAMINATION_MODULE_NAME]: examinationReducer,
    [REDUX_CODING_MODULE_NAME]: codingReducer,
    router: connectRouter(history),
  });
};

// Мидлвара для саг
const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store: Store<AppState> =
  process.env.NODE_ENV !== 'production'
    ? createStore(
        createRootReducer(history),
        defaultState,
        composeEnhancer(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
      )
    : createStore(createRootReducer(history), defaultState, applyMiddleware(routerMiddleware(history), sagaMiddleware));

sagaMiddleware.run(rootSaga);
