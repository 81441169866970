import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'reflect-metadata';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/browser';
import { ConnectedRouter } from 'connected-react-router';

import { safeLocalstorageGetItem } from './utils/safeStorage';
import App from './App';
import { history, store } from './state';
// import * as serviceWorker from './serviceWorker';

if (typeof window.crypto === 'undefined' && typeof window.msCrypto !== 'undefined') {
  // @ts-ignore
  window.crypto = window.msCrypto;
}

const apolloLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authApolloLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = safeLocalstorageGetItem('api_at');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const graphqlClient = new ApolloClient({
  link: authApolloLink.concat(apolloLink),
  cache: new InMemoryCache(),
});

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    ignoreErrors: [
      'Language client is not ready yet',
      'Connection is disposed',
      'Connection got disposed.',
      'Unexpected token y in JSON',
    ],
  });
}

const completeApp = (
  <ApolloProvider client={graphqlClient}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route component={App} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </ApolloProvider>
);
ReactDOM.render(completeApp, document.getElementById('root'));

export default completeApp;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
