import { Company } from '../../../models/auth/Company';
import { User } from '../../../models/auth/User';
import { safeLocalstorageGetItem } from '../../../utils/safeStorage';

export const REDUX_AUTH_MODULE_NAME = 'auth';

export interface AuthState {
  accessToken?: string;
  user?: User;
  company?: Company;
  currentCompanyId?: string;
  showProgress: boolean;
  canSwitchCompanies: boolean;
  maintenanceMode?: boolean;
}

export const initialAuthState: AuthState = {
  accessToken: safeLocalstorageGetItem('api_at'),
  currentCompanyId: safeLocalstorageGetItem('api_comp_id'),
  showProgress: false,
  canSwitchCompanies: false,
};
