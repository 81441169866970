import React from 'react';

import Logo from '../../components/Svg/Logo';
import { FormattedMessageWithDefault } from '../../shared/formatted-message-with-default';

const WelcomePage: React.FunctionComponent<{}> = () => (
  <div className="skilltest__intro-page">
    <div>
      <Logo />
    </div>
    <p className="skills-welcome__paragraph">
      <FormattedMessageWithDefault
        id="skills.public.welcome.about"
        values={{
          link: <a href="https://geecko.ru?from=skills">geecko.ru</a>,
        }}
      />
    </p>
  </div>
);

export default WelcomePage;
