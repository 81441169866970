import { Expose, plainToClass, Type } from 'class-transformer';

import { TestQuestion } from './TestQuestion';

export class Test {
  /**
   * Создает экземпляр тестирования из JSON объекта.
   *
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(Test, json);
  }

  public id!: number;

  public name!: string;

  @Expose({ name: 'public_name' })
  public publicName!: string;

  @Type(() => TestQuestions)
  public questions!: TestQuestions;
}

export class TestQuestions {
  public count!: number;

  @Type(() => TestQuestion)
  public questions!: TestQuestion[];
}

export enum TestChoiceType {
  SingleAnswer = 1,
  MultipleAnswer,
}

export enum TestMultipleChoiceAssessmentType {
  RequireAllCorrect = 1,
}
