export const safeLocalstorageGetItem = (key: string): any => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return undefined;
  }
};

export const safeLocalstorageSetItem = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    //
  }
};

export const safeLocalstorageRemoveItem = (key: string): any => {
  try {
    return localStorage.removeItem(key);
  } catch (e) {
    return undefined;
  }
};
