import { ActionType, getType } from 'typesafe-actions';

import { safeLocalstorageRemoveItem, safeLocalstorageSetItem } from '../../../utils/safeStorage';

import * as AuthAction from './actionCreators';
import { AuthState, initialAuthState } from './types';

type AuthActionType = ActionType<typeof AuthAction>;

export const authReducer = (state: AuthState = initialAuthState, action: AuthActionType): AuthState => {
  switch (action.type) {
    case getType(AuthAction.setAccessTokenAction): {
      if (action.payload) {
        safeLocalstorageSetItem('api_at', action.payload);
      } else {
        safeLocalstorageRemoveItem('api_at');
      }
      return {
        ...state,
        accessToken: action.payload,
      };
    }
    case getType(AuthAction.setCurrentCompanyIdAction): {
      if (action.payload) {
        safeLocalstorageSetItem('api_comp_id', action.payload);
      } else {
        safeLocalstorageRemoveItem('api_comp_id');
      }
      return {
        ...state,
        currentCompanyId: action.payload,
      };
    }
    case getType(AuthAction.setUserAction): {
      return {
        ...state,
        user: action.payload,
      };
    }
    case getType(AuthAction.setCompanyAction): {
      return {
        ...state,
        company: action.payload,
      };
    }
    case getType(AuthAction.setShowProgressAction): {
      return {
        ...state,
        showProgress: action.payload,
      };
    }
    case getType(AuthAction.setCanSwitchCompaniesAction): {
      return {
        ...state,
        canSwitchCompanies: action.payload,
      };
    }
    case getType(AuthAction.setMaintenanceMode): {
      return {
        ...state,
        maintenanceMode: action.payload,
      };
    }
    default:
      return state;
  }
};
