import { createSelector } from 'redux-views';

import { getFallbackLanguage } from '../../../misc/fallback-language';
import { AppState } from '../../index';

import { REDUX_COMMON_MODULE_NAME } from './types';

const commonStateSelector = (state: AppState) => state[REDUX_COMMON_MODULE_NAME];

export const localeSelector = createSelector([commonStateSelector], (commonState) => commonState.locale);
export const forceLocaleSelector = createSelector(
  [commonStateSelector],
  (commonState) => commonState.locale || getFallbackLanguage(),
);

export const serverTimeDifferenceSelector = createSelector(
  [commonStateSelector],
  (commonState) => commonState.serverTimeDifference || 0,
);

export const keyboardKeySelector = createSelector([commonStateSelector], (commonState) => commonState.keyboardKey);

export const taskScoreSelector = createSelector([commonStateSelector], (commonState) => commonState.taskScore);
export const taskScoreMaxSelector = createSelector([commonStateSelector], (commonState) => commonState.taskScoreMax);
