import { AppState } from './index';

export interface NetworkEntitiesCommon {
  loading?: boolean;
  loaded?: boolean;
  error?: Error;
}

export interface NetworkEntity<T> extends NetworkEntitiesCommon {
  item?: T;
}

export interface NetworkEntitiesCollecion<T> extends NetworkEntitiesCommon {
  items?: T[];
}

export type NetworkEntitiesCollecionSelector<T> = (
  state: AppState,
) => {
  loading: boolean;
  loaded: boolean;
  items: T[];
  error?: Error;
};

export type NetworkEntitySelector<T> = (
  state: AppState,
) => {
  loading: boolean;
  loaded: boolean;
  item?: T;
  error?: Error;
};

export function getNetworkEntitiesCollectionSelectorState<T>(state: NetworkEntitiesCollecion<T>) {
  return {
    loading: !!state.loading,
    loaded: !!state.loaded,
    error: state.error,
    items: state.items || [],
  };
}

export function getNetworkEntitySelectorState<T>(state: NetworkEntity<T>) {
  return {
    loading: !!state.loading,
    loaded: !!state.loaded,
    error: state.error,
    item: state.item,
  };
}
