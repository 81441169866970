import { produce } from 'immer';
import { ActionType, getType } from 'typesafe-actions';

import * as CodingAction from './actionCreators';
import { CodingState, initialCodingState } from './types';

type CodingActionType = ActionType<typeof CodingAction>;

const codingReducer = (state: CodingState = initialCodingState, action: CodingActionType): CodingState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case getType(CodingAction.clearCodingOutputAction):
        draft.output = [];
        break;

      case getType(CodingAction.addCodingOutputAction):
        draft.output = [...draft.output, ...(Array.isArray(action.payload) ? action.payload : [action.payload])];
        break;

      case getType(CodingAction.setCodingOutputAction):
        draft.output = Array.isArray(action.payload) ? action.payload : [action.payload];
        break;
    }
  });
};

export default codingReducer;
