import { Expose } from 'class-transformer';

export class Role {
  @Expose({ name: 'id' })
  public readonly id!: number;

  @Expose({ name: 'name' })
  public readonly name!: string;

  @Expose({ name: 'code' })
  public readonly code!: string;
}
