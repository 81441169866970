import queryString from 'query-string';

import { safeLocalstorageGetItem, safeLocalstorageSetItem } from '../utils/safeStorage';

export const getFallbackLanguage = () => {
  const parsedQuery = queryString.parseUrl(window.location.href);
  if (
    parsedQuery.query.lang &&
    typeof parsedQuery.query.lang === 'string' &&
    ['ru', 'en'].indexOf(parsedQuery.query.lang) >= 0
  ) {
    safeLocalstorageSetItem('lang', parsedQuery.query.lang);
    return parsedQuery.query.lang;
  }

  const languageFromStorage = safeLocalstorageGetItem('lang');
  if (languageFromStorage && ['ru', 'en'].indexOf(languageFromStorage) >= 0) {
    return languageFromStorage;
  }

  return navigator.language.split(/[-_]/)[0];
};
