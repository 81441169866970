import { Expose, Type } from 'class-transformer';

export enum DataType {
  Integer = 'int',
  String = 'string',
  Void = 'void',
  Boolean = 'bool',
  Float = 'float',
  ArrayInt = 'array_of_int',
  ArrayString = 'array_of_int',
}

export class UniversalTaskStructure {
  @Expose({ name: 'function_name' })
  public functionName!: string;

  @Type(() => UniversalTaskArgument)
  @Expose({ name: 'arguments' })
  public arguments!: UniversalTaskArgument[];

  @Expose({ name: 'return_type' })
  public returnType!: DataType;
}

export class UniversalTaskArgument {
  @Expose({ name: 'name' })
  public name!: string;

  @Expose({ name: 'type' })
  public type!: DataType;
}
