import { OutputItem } from '@Components/ConsoleOutput/ConsoleOutput';

import codingReducer from './reducer';

export const REDUX_CODING_MODULE_NAME = 'coding';

export interface CodingState {
  output: OutputItem[];
}

export const initialCodingState: CodingState = {
  output: [],
};

export { codingReducer };
