import { Expose, plainToClass, Type } from 'class-transformer';
import { DatabaseEnvironment } from 'models/tech/DatabaseEnvironment';

export class DatabaseTask {
  /**
   * Создает экземпляр задания из JSON объекта.
   *
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(DatabaseTask, json);
  }

  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'name' })
  public name!: string;

  @Expose({ name: 'environment' })
  @Type(() => DatabaseEnvironment)
  public environment!: DatabaseEnvironment;

  @Expose({ name: 'instructions' })
  public instructions!: string;

  @Expose({ name: 'time_limit' })
  public timeLimit!: number | null;

  @Expose({ name: 'difficulty' })
  public difficulty!: number | null;

  @Expose({ name: 'is_demo' })
  public isDemo!: boolean;

  @Expose({ name: 'is_schema_readonly' })
  public isSchemaReadonly!: boolean;

  @Expose({ name: 'dont_check_rows_order' })
  public dontCheckRowsOrder!: boolean;

  @Expose({ name: 'schema_sql_hidden' })
  public schemaSqlHidden!: boolean;

  @Expose({ name: 'schema_sql' })
  public schemaSql!: string;

  @Expose({ name: 'test_sql' })
  public testSql!: string;
}
