import { createAction } from 'typesafe-actions';

import { Company } from '@Models/auth/Company';
import { User } from '@Models/auth/User';

/**
 * Установка токена
 */
export const setAccessTokenAction = createAction('auth/set-access-token')<string | undefined>();

/**
 * Установка пользователя
 */
export const setUserAction = createAction('auth/set-user')<User | undefined>();

/**
 * Установка компании
 */
export const setCompanyAction = createAction('auth/set-company')<Company | undefined>();

/**
 * Установка ID компании
 */
export const setCurrentCompanyIdAction = createAction('auth/set-current-company-id')<string | undefined>();

/**
 * Установка showProgress
 */
export const setShowProgressAction = createAction('auth/set-show-progress')<boolean>();

/**
 * Установка canSwitchCompanies
 */
export const setCanSwitchCompaniesAction = createAction('auth/set-can-switch-companies')<boolean>();

/**
 * Отмечаем что мы в maintenance mode
 */
export const setMaintenanceMode = createAction('auth/set-maintenance-mode')<boolean | undefined>();
