import { library } from '@fortawesome/fontawesome-svg-core';
import { faBitbucket } from '@fortawesome/free-brands-svg-icons/faBitbucket';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faGitlab } from '@fortawesome/free-brands-svg-icons/faGitlab';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faSkype } from '@fortawesome/free-brands-svg-icons/faSkype';
import { faStackOverflow } from '@fortawesome/free-brands-svg-icons/faStackOverflow';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons/faThumbsDown';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';

library.add(faSpinner, faEdit, faTrash, faPlus, faCheck, faTimes, faExclamationTriangle, faStar, faClock);
library.add(faArrowLeft, faArrowRight, faFileAlt, faBan, faQuestionCircle, faCog, faThumbsUp, faThumbsDown);
library.add(faSkype, faLinkedin, faGithub, faGitlab, faBitbucket, faStackOverflow, faExternalLinkAlt);
