import { Expose, plainToClass } from 'class-transformer';

/**
 * Модель языка программирования
 */
export class Language {
  /**
   * Создает экземпляр языка из JSON объекта.
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(Language, json);
  }

  @Expose({ name: 'id' })
  public readonly id!: number;

  @Expose({ name: 'name' })
  public readonly name!: string;

  @Expose({ name: 'code' })
  public readonly code!: string;

  @Expose({ name: 'code_monaco' })
  public readonly codeMonaco!: string;
}
