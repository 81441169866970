import { ActionType, getType } from 'typesafe-actions';

import { getUnixTime } from '../../../helpers';

import * as CommonAction from './actionCreators';
import { CommonState, initialCommonState } from './types';

type CommonActionType = ActionType<typeof CommonAction>;

export const commonReducer = (state: CommonState = initialCommonState, action: CommonActionType): CommonState => {
  switch (action.type) {
    case getType(CommonAction.setIsPageLockedAction): {
      return {
        ...state,
        lockPageScroll: action.payload,
      };
    }

    case getType(CommonAction.setServerTimeDifferenceAction): {
      return {
        ...state,
        serverTimeDifference: getUnixTime() - action.payload,
      };
    }

    case getType(CommonAction.addTaskEventsAction): {
      return {
        ...state,
        tasksEvents: [...state.tasksEvents, ...action.payload],
      };
    }

    case getType(CommonAction.removeTaskEventsAction): {
      return {
        ...state,
        tasksEvents: [],
      };
    }

    case getType(CommonAction.setKeyboardKeyAction): {
      return {
        ...state,
        keyboardKey: action.payload,
      };
    }

    case getType(CommonAction.setLocaleAction): {
      return {
        ...state,
        locale: action.payload,
      };
    }

    case getType(CommonAction.setTaskScoresAction): {
      return {
        ...state,
        taskScore: action.payload.score,
        taskScoreMax: action.payload.scoreMax,
      };
    }

    default:
      return state;
  }
};
