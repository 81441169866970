import { ActionType, getType } from 'typesafe-actions';
import {
  initialEditorState,
  EditorState,
  REDUX_EDITOR_FONT_SIZE_PERSIST_KEY,
  REDUX_EDITOR_COLOR_SCHEME_PERSIST_KEY, REDUX_EDITOR_WORD_WRAP_PERSIST_KEY,
} from './types';
import * as EditorAction from './actionCreators';
import { safeLocalstorageSetItem } from '../../../utils/safeStorage';

type EditorActionType = ActionType<typeof EditorAction>;

export const editorReducer = (state: EditorState = initialEditorState, action: EditorActionType): EditorState => {
  switch (action.type) {
    case getType(EditorAction.setEditorFontSizeAction): {
      safeLocalstorageSetItem(REDUX_EDITOR_FONT_SIZE_PERSIST_KEY, String(action.payload));

      return {
        ...state,
        fontSize: action.payload,
      };
    }
    case getType(EditorAction.setEditorColorSchemeAction): {
      safeLocalstorageSetItem(REDUX_EDITOR_COLOR_SCHEME_PERSIST_KEY, action.payload);
      return {
        ...state,
        colorScheme: action.payload,
      };
    }
    case getType(EditorAction.setEditorWordWrapAction): {
      safeLocalstorageSetItem(REDUX_EDITOR_WORD_WRAP_PERSIST_KEY, action.payload ? '1' : '');
      return {
        ...state,
        wordWrap: action.payload,
      };
    }
    default:
      return state;
  }
};
