import { getFallbackLanguage } from '../../../misc/fallback-language';

export const REDUX_COMMON_MODULE_NAME = 'common';

export interface CommonState {
  locale: string;
  lockPageScroll?: boolean;
  serverTimeDifference?: number;
  tasksEvents: string[];
  keyboardKey?: string;

  // TODO: Перенести эти переменные в отдельный модуль
  taskScore: number;
  taskScoreMax: number;
}

export const initialCommonState: CommonState = {
  locale: getFallbackLanguage(),
  tasksEvents: [],
  taskScore: 0,
  taskScoreMax: 0,
};
