import { createAction } from 'typesafe-actions';

/**
 * Установка блокировки скролла страницы
 */
export const setIsPageLockedAction = createAction('common/set-scroll-locked')<boolean>();

/**
 * Установка разницы с серверным временем
 */
export const setServerTimeDifferenceAction = createAction('common/set-server-time-difference')<number>();

/**
 * Добавление идентификаторов событий в пул
 */
export const addTaskEventsAction = createAction('common/add-task-events')<string[]>();

/**
 * Удаление идентификаторов событий из пула
 */
export const removeTaskEventsAction = createAction('common/remove-task-events')();

/**
 * Установка ключа клавиатуры
 */
export const setKeyboardKeyAction = createAction('common/set-keyboard-key')<string | undefined>();

/**
 * Установка локали
 */
export const setLocaleAction = createAction('common/set-locale')<string>();

/**
 * Установка результатов таска
 */
export const setTaskScoresAction = createAction('common/set-task-score')<{ score: number; scoreMax: number }>();
