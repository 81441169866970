import { Expose, plainToClass } from 'class-transformer';

export class TestCase {
  /**
   * Создает экземпляр тест-кейса из JSON объекта.
   *
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(TestCase, json);
  }

  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'name' })
  public name!: string;

  @Expose({ name: 'data' })
  public data!: any;

  @Expose({ name: 'score' })
  public score!: number | null;

  @Expose({ name: 'is_sample' })
  public isSample!: boolean;
}

export class PerformanceTestCase {
  /**
   * Создает экземпляр тест-кейса из JSON объекта.
   *
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(PerformanceTestCase, json);
  }

  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'name' })
  public name?: string;

  @Expose({ name: 'input' })
  public input?: string;

  @Expose({ name: 'input_size' })
  public inputSize!: number;

  @Expose({ name: 'input_hidden' })
  public inputHidden!: boolean;

  @Expose({ name: 'input_value_fmt' })
  public inputValueFormatted!: string;

  @Expose({ name: 'generator_language' })
  public generatorLanguage!: string;

  @Expose({ name: 'generator_code' })
  public generatorCode!: string;

  @Expose({ name: 'use_generator' })
  public useGenerator!: boolean;

  @Expose({ name: 'is_active' })
  public isActive!: boolean;

  @Expose({ name: 'time_limit' })
  public timeLimit!: { all: string; [key: string]: string };
}

export const getBasicTestCaseProps = (testCase: TestCase): unknown => ({
  id: testCase.id,
  name: testCase.name,
  data: testCase.data,
  is_sample: testCase.isSample,
});
