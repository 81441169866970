import { Expose, plainToClass, Type } from 'class-transformer';
import { Template } from 'models/tests/template/Template';

export class Promo {
  /**
   * Создает экземпляр промо из JSON объекта.
   * @param json
   */
  public static fromJson(json: object) {
    return plainToClass(Promo, json);
  }

  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'url' })
  public url!: string;

  @Expose({ name: 'name' })
  public name!: string;

  @Expose({ name: 'agreement_file' })
  public agreementFilename!: string;

  @Expose({ name: 'agreement_url' })
  public agreementUrl!: string;

  @Expose({ name: 'public_url' })
  public publicUrl!: string;

  @Expose({ name: 'welcome_text' })
  public welcomeText!: string;

  @Expose({ name: 'fields' })
  @Type(() => PromoField)
  public fields!: PromoField[];

  @Expose({ name: 'templates' })
  @Type(() => PromoTemplate)
  public templates!: PromoTemplate[];

  @Expose({ name: 'tests_created' })
  public testsCreated!: number;

  @Expose({ name: 'tests_finished' })
  public testsFinished!: number;
}

export class PromoField {
  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'name' })
  public name!: string;

  @Expose({ name: 'type' })
  public type!: string;

  @Expose({ name: 'position' })
  public position!: number;

  @Expose({ name: 'is_required' })
  public isRequired!: boolean;
}

export class PromoTemplate {
  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'template' })
  @Type(() => Template)
  public template!: Template;
}
