import { Expose } from 'class-transformer';

export class DatabaseDriver {
  @Expose({ name: 'id' })
  public id!: number;

  @Expose({ name: 'name' })
  public name!: string;

  @Expose({ name: 'code' })
  public code!: string;
}
