import { createAction } from 'typesafe-actions';

/**
 * Установка размера шрифта
 */
export const setEditorFontSizeAction = createAction('editor/set-font-size')<number>();

/**
 * Установка цветовой схемы
 */
export const setEditorColorSchemeAction = createAction('editor/set-color-scheme')<string>();

/**
 * Установка режима переноса слов
 */
export const setEditorWordWrapAction = createAction('editor/set-word-wrap')<boolean>();
