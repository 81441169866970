import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Form } from '@ant-design/compatible';
import { setAccessTokenAction } from '@State/modules/auth/actionCreators';
import { authIsLogged } from '@State/modules/auth/selectors';
import { Alert, Button, Input } from 'antd';
import Api, { ApiErrorResponse } from 'services/api';

import './login-page.css';
import '@ant-design/compatible/assets/index.css';

interface FormValues {
  email: string;
  password: string;
}

export const LoginPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLogged = useSelector(authIsLogged);

  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [formData, setFormData] = useState<FormValues>({
    email: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState<FormValues>({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (isLogged) {
      history.replace('/management/sessions');
    }
  }, [isLogged]);

  const handleEmailChange = (event: React.ChangeEvent<any>) => {
    const { value } = event.target;
    setFormData((prev) => ({ ...prev, email: value }));
  };

  const handlePasswordChange = (event: React.ChangeEvent<any>) => {
    const { value } = event.target;
    setFormData((prev) => ({ ...prev, password: value }));
  };

  const signIn = () => {
    setLoading(true);
    setLoginError('');
    setFormErrors({ email: '', password: '' });

    Api.post('/signin', formData)
      .then((response) => {
        if (response.success === true) {
          dispatch(setAccessTokenAction(response.access_token));
          history.push('/management/sessions');
        }
      })
      .catch((error: ApiErrorResponse) => {
        setTimeout(() => {
          setLoading(false);

          if (error.status === 422) {
            if (error.validation!.email && error.validation!.email.length > 0) {
              setFormErrors((prev) => ({ ...prev, email: error.validation!.email[0] }));
            }
            if (error.validation!.password && error.validation!.password.length > 0) {
              setFormErrors((prev) => ({ ...prev, password: error.validation!.password[0] }));
            }
          } else if (error.status === 400) {
            setLoginError(error.message);
          }
        }, 500);
      });
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    signIn();
  };

  return (
    <>
      <div className="login-page__layout">
        <form className="content-form" onSubmit={onSubmit}>
          <Form.Item
            label={<span>Email</span>}
            htmlFor="login-form__email"
            validateStatus={formErrors.email ? 'error' : ''}
          >
            <Input
              id="login-form__email"
              size="large"
              value={formData.email}
              onChange={handleEmailChange}
              disabled={loading}
            />
            {formErrors.email && <div className="ant-form-explain">{formErrors.email}</div>}
          </Form.Item>

          <Form.Item
            label={<span>Пароль</span>}
            htmlFor="login-form__password"
            validateStatus={formErrors.password ? 'error' : ''}
          >
            <Input
              type="password"
              size="large"
              value={formData.password}
              onChange={handlePasswordChange}
              disabled={loading}
            />
            {formErrors.password && <div className="ant-form-explain">{formErrors.password}</div>}
          </Form.Item>

          {loginError && <Alert type="error" message={loginError} />}

          <div className="content-form__item">
            <Button type="primary" htmlType="submit" size="large" loading={loading}>
              Войти
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
